<template>
  <div>
    <el-upload
      action="https://fleetan-hk-bucket.oss-accelerate.aliyuncs.com"
      :data="dataObj"
      list-type="text"
      :multiple="false"
      :file-list="fileList"
			:show-file-list="showUploadList"
      :before-upload="beforeUpload"
      :on-remove="handleRemove"
      :on-success="handleUploadSuccess"
      :on-preview="handlePreview"
      accept=".xls, .xlsx"
    >
      <el-button size="small" type="primary">{{btnName}}</el-button>
      <div slot="tip" class="el-upload__tip">{{btnTip}}</div>
    </el-upload>
    <!-- <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="fileList[0].url" alt />
    </el-dialog> -->
  </div>
</template>
<script>
import { policy } from "./policy";
import { createUniqueString } from "@/utils/index";

export default {
  name: "singleUpload",
  props: {
    value: String,
		preFile:{
			type:Array,
			default:function(){
				return [];
			}
		},
		tipText:{
			type: String,
			default:function(){
				return '上传清单附件-只能上传xls/xlsx文件'
			}
		},
		orderNo:{
			type: String,
			default:function(){
				return ''
			}
		},
		showUploadList:{
			type:Boolean,
			default:function(){
				return true
			}
		}
  },
	watch:{
		//监听按钮名称和提示文字
		'tipText':{
			handler(newValue){
				let nameStr = newValue.split('-');
				this.btnName = nameStr[0];
				this.btnTip = nameStr[1];
			},
			immediate: true,
		},
		// 监听订单号
		'orderNo':{
			handler(newValue){
				if(newValue == null || newValue == ''){
					this.uploadOrderNo = '';
				}else{
					this.uploadOrderNo = newValue + '_';
				}
			},
			immediate: true,
		},
		//监听预览文件，有值显示在fileList中
		preFile:{
			handler(newValue){
				this.fileList = [];
				if(newValue.length > 0){
					this.showFileList = true;
					newValue.forEach(item => {
						this.fileList.push({
							name:item.name,
							url:item.url,
						})
					})
				}
			},
			immediate: true,
		}
	},
  computed: {
   //  imageUrl() {
   //    return this.value;
   //  },
   //  imageName() {
   //    if (this.value != null && this.value !== "") {
   //      return this.value.substr(this.value.lastIndexOf("/") + 1);
   //    } else {
   //      return null;
   //    }
   //  },
   //  fileList: {
			// get:function(){
			// 	return [
			// 	  {
			// 	    name: this.imageName,
			// 	    url: this.imageUrl
			// 	  }
			// 	];
			// },
			// set:function(v){
				
			// }
      
   //  },
    showFileList: {
      get: function() {
        return (
          this.value !== null && this.value !== "" && this.value !== undefined
        );
      },
      set: function(newValue) {}
    }
  },
  data() {
    return {
      dataObj: {
        policy: "",
        signature: "",
        key: "",
        ossaccessKeyId: "",
        dir: "",
        host: ""
        //callbackUrl: '',
        //callbackBody: '',
        //callbackBodyType: ''
        // callback:'',
      },
      dialogVisible: false,
			fileList:[{
				name:'',
				url:'',
			}],
			uploadOrderNo:'',
			btnName:'上传清单附件',
			btnTip:'只能上传xls/xlsx文件',
    };
  },
  methods: {
    emitInput(val) {
      this.$emit("input", val);
    },
    handleRemove(file, fileList) {
      this.emitInput("");
    },
    handlePreview(file) {
      // this.dialogVisible = true;
			window.open(file.url, "_blank");
    },
    beforeUpload(file) {
      let _self = this;
			let fileNameStr = file.name;
			if(fileNameStr.length > 30){
				return this.$message.warning(this.$t('tipsInfo.uploadFileNameLimitTips'));
			}
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "xls";
      const extension2 = testmsg === "xlsx";
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 xls、xlsx格式!",
          type: "warning"
        });
        return extension || extension2;
      } else {
        this.$message({
          message: "正在上传，请稍后",
          typpe: "warning"
        });
      }

      // policy().then(response => {

      //     _self.dataObj.policy = response.data.policy;
      //     _self.dataObj.signature = response.data.signature;
      //     _self.dataObj.ossaccessKeyId = response.data.accessid;
      //     _self.dataObj.key = response.data.dir +'~${filename}';
      //     _self.dataObj.dir = response.data.dir;
      //     _self.dataObj.host = response.data.host;

      //     //_self.dataObj.callbackUrl = response.data.callbackUrl;
      //     //_self.dataObj.callbackBody = response.data.callbackBody;
      //     //_self.dataObj.callbackBodyType = response.data.callbackBodyType;

      //     console.log(_self.dataObj);

      //   }).catch(err => {
      //     this.msgSuccess("上传失败");
      //   })
      return new Promise((resolve, reject) => {
        policy()
          .then(response => {
            // console.log(_self.dataObj);
            // console.log(response);

            _self.dataObj.policy = response.policy;
            _self.dataObj.signature = response.signature;
            _self.dataObj.ossaccessKeyId = response.accessid;
						// zpy 2022-5-24 上传附件名称加上订单号，方便批量下载区分订单
            _self.dataObj.key =
              response.dir + createUniqueString() + "~" + this.uploadOrderNo + "${filename}";
            _self.dataObj.dir = response.dir;
            _self.dataObj.host = response.host;
            // console.log(_self.dataObj);
            resolve(true);
          })
          .catch(err => {
            reject(false);
          });
      });
    },
    // 上传完成
    handleUploadSuccess(res, file) {
      this.$message.success("上传成功...");
      this.showFileList = true;
      this.fileList.pop();
			let fileName = encodeURIComponent(file.name);
			let url = this.dataObj.host + "/" + this.dataObj.key.replace("${filename}", fileName)
      // this.fileList.push({
      //   name: file.name,
      //   url:
      //     this.dataObj.host +
      //     "/" +
      //     this.dataObj.key.replace("${filename}", file.name)
      // });
      this.emitInput(url);
      // console.log(this.fileList[0].url);
    }
  }
};
</script>
<style>
</style>


